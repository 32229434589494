import { Element } from "@/scripts/extends";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { EventEmitter } from "@/scripts/core";
import { EVENTS } from "@/scripts/constants";

export default class SiteHeader extends Element {
	mounted() {
		this.isOpen = false;
		this.siteLogo = this.el.querySelector(".c-site-header__logo");
		this.trigger = this.el.querySelector(".c-site-header__hamburger");

		this.initScrollAnimation();

		this.disable();
	}

	events() {
		if (!this.trigger) {
			return;
		}

		this.trigger.addEventListener("click", (e) => this.toggle(e));
		window.addEventListener("scroll", () => this.handleScroll());
	}

	initScrollAnimation() {
		this.animation = gsap.from(this.el, {
			yPercent: -150,
			autoAlpha: 1,
			paused: true,
			duration: 0.33,
		}).progress(1);

		ScrollTrigger.create({
			start: "top top-=200px",
			end: 99999,
			onUpdate: (self) => {
				if (self.direction === -1) {
					this.animation.play();

					return;
				}

				this.animation.reverse();
			},
		});
	}

	handleScroll() {
		if (this.isOpen) {
			EventEmitter.emit(EVENTS.NAV_IS_OPEN, true);

			this.disable();
		}
	}

	toggle() {
		if (this.isOpen) {
			this.disable();
		} else {
			this.enable();
		}
	}

	enable() {
		this.el.classList.add("is-open");
		this.isOpen = true;
	}

	disable() {
		this.el.classList.remove("is-open");
		this.isOpen = false;
	}

}
