export default {
	VP_3: 340,
	VP_5: 568,
	VP_6: 640,
	VP_7: 768,
	VP_9: 960,
	VP_12: 1280,
	VP_14: 1440,
	VP_15: 1536,
};
