import { DomManager, Store } from "@/scripts/core";
import components from "@/scripts/components";
import modules from "@/scripts/modules";
import AOS from "aos";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { BREAKPOINTS } from "@/scripts/constants";

/* Load in css libraries */
// DOCS: https://swiperjs.com/
import "swiper/css";

// DOCS: https://github.com/michalsnik/aos
import "aos/dist/aos.css";

// DOCS: https://atomiks.github.io/tippyjs/
// import "tippy.js/dist/tippy.css";

/**
 * Set up the initial values and keys for the store. This should be done first
 * thing before mounting and creating the components.
 */
Store.setState({});

// EXTEND:Sprig + Vanilla JS
function init() {
	DomManager.setComponents(components);
	DomManager.setModules(modules);
}

DomManager.define({
	/**
	 * Triggers first and immediately, does not wait for a state. Only
	 * initialize DOM based classes that do not require calculations with
	 * dimensions, because the dimensions of certain elements might not be set
	 * immediately.
	 */
	created() {
		const isReduced =
			window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
			window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;

		const isMobile = (window.innerWidth < BREAKPOINTS.VP_7);

		if (isReduced || isMobile) {
			// DON'T use an animation here!
			AOS.init({
				disable: true,
			});
		} else {
			// DO use an animation here!
			AOS.init({
				once: true,
			});
		}

		gsap.registerPlugin(ScrollTrigger);
		gsap.registerPlugin(ScrollToPlugin);
	},

	/**
	 * Triggers on 'interactive' state, if 'interactive' never fires, it is
	 * triggered on 'complete' state. Dimensions are known and set
	 */
	mounted() {
		init();
	},

	// Triggers last and on 'complete' state
	completed() {},
});

// EXTEND:Sprig + Vanilla JS
document.addEventListener("sprig-refresh", () => {
	DomManager.destroy();
	init();
});
