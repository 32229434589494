import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

import { Element } from "@/scripts/extends";

export default class Slider extends Element {
	mounted() {
		if (this.el.classList.contains("swiper")) {
			if(window.innerWidth < 768) {
				this.setSwiper();
			}
		}
	}

	events() {
		window.addEventListener('resize', () => {
			if(window.innerWidth < 768) {
				this.setSwiper();
			} else {
				if (this.swiper) {
					this.swiper.destroy();
				}
			}
		});
	}

	setSwiper() {
		const swiperConfig = {
			modules: [Navigation],
			direction: 'horizontal',
			slidesPerView: 1.5,
			spaceBetween: 8,
			slideActiveClass: "is-active",
			slidesOffsetBefore: 32,
			slidesOffsetAfter: 32,
			navigation: {
				nextEl: ".c-comparison__button--next",
				prevEl: ".c-comparison__button--prev",
				disabledClass: "opacity-50 pointer-events-none",
			},
		};

		this.swiper = new Swiper(this.el, swiperConfig);

		this.swiper.init();
	}
}
