import { Element } from "@/scripts/extends";
import { EventEmitter } from "@/scripts/core";
import { EVENTS } from "@/scripts/constants";

export default class Hamburger extends Element {
	mounted() {
		this.openClass = "is-cross";
		this.isOpen = this.el.classList.contains(this.openClass);

		this.label = this.el.dataset.label;
		this.openLabel = this.el.dataset.labelOpen;
		this.closeLabel = this.el.dataset.labelClose;
	}

	events() {
		EventEmitter.on(EVENTS.NAV_IS_OPEN, (isOpen) => this.handleNavIsOpen(isOpen));

		this.el.addEventListener("click", (e) => this.handleClick(e));
	}

	handleNavIsOpen(isOpen) {
		if (isOpen) {
			this.close();
		}
	}

	handleClick(e) {
		if (e) {
			this.toggle();
		}
	}

	toggle() {
		if (this.isOpen) {
			this.close();
		} else {
			this.open();
		}
	}

	close() {
		this.isOpen = false;

		this.el.classList.remove(this.openClass);
		this.el.setAttribute("aria-label", this.openLabel);
		this.el.setAttribute("aria-expanded", "true");
	}

	open() {
		this.isOpen = true;

		this.el.classList.add(this.openClass);
		this.el.setAttribute("aria-label", this.closeLabel);
		this.el.setAttribute("aria-expanded", "false");
	}
}
